import React from "react";
import { SEO, PageLayout, ContactForm } from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class JoinOurTeamPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={null}>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"left"}
            centered={true}
          >
            <Grid.Column width={10} textAlign={"center"}>
              <h1>Join Our Team</h1>
              <p>
                Are you new to the area or looking for a new place to work? At
                GHQ, we have 2 locations in Virginia Beach. We are always
                accepting applications for Stylists or Front Desk Coordinator.
              </p>
            </Grid.Column>
            <Grid.Column width={10}>
              <ContactForm
                constructMessage={({ name, email, phone, position, message }) =>
                  `Someone has reached out to you via your website team form: Name: ${name}, Email: ${email}, Phone: ${phone}, Position: ${position}, Message: ${message}`
                }
                fields={[
                  {
                    label: "name",
                    placeholder: "Name",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "email",
                    displayLabel: "Email",
                    placeholder: "Email",
                    type: "input",
                    inputType: "email",
                    required: true,
                  },
                  {
                    label: "phone",
                    displayLabel: "Phone Number",
                    placeholder: "Phone Number",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "position",
                    displayLabel: "Position",
                    placeholder: "Position",
                    type: "select",
                    options: [
                      { label: "Stylist", value: "Stylist" },
                      {
                        label: "Front Desk Coordinator",
                        value: "Front Desk Coordinator",
                      },
                    ],
                  },
                  {
                    label: "message",
                    placeholder: "Message",
                    type: "textarea",
                    required: false,
                  },
                ]}
                buttonText={"Send"}
                centeredHeaders={true}
                className={""}
                fluidButton={true}
                header={""}
                inline={false}
                showLabels={true}
                subheader={""}
                termsCopy={""}
                withContainer={true}
                locationId={fishermanBusiness.primaryLocation._id}
                businessId={fishermanBusiness._id}
                businessType={fishermanBusiness.type}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        _id
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(title: { eq: "Join Our Team" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
      }
    }
  }
`;
